"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.InnerSkeleton = exports.BannerSkeletonRoot = void 0;
var _mui = require("@ori-ui/mui");
var _styles = require("../../../BannerWithProductsAndButton/components/Banner/styles");
var _styles2 = require("../../../styles");
const BannerSkeletonRoot = exports.BannerSkeletonRoot = (0, _mui.styled)(_styles.Root)({
    padding: 0
});
const InnerSkeleton = exports.InnerSkeleton = (0, _mui.styled)(_styles2.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 0
        }
    };
});
