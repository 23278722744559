"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.onImageError = void 0;
// istanbul ignore file -- No need to test image loading error
const onImageError = (event)=>{
    const target = event.currentTarget;
    target.style.display = 'none';
    target.setAttribute('aria-hidden', 'true');
};
exports.onImageError = onImageError;
