"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = exports.Circle = exports.BannerWrapper = void 0;
var _mui = require("@ori-ui/mui");
var _presentationUtils = require("@ori/presentation-utils");
var _constants = require("./components/Banner/constants");
const Root = exports.Root = (0, _mui.styled)('section', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme, bannerAlignment, bannerOrientation } = param;
    const gridRow = bannerAlignment === 'left' ? "'banner products'" : "'products banner'";
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        padding: theme.spacing(0, 3),
        maxWidth: 1346,
        margin: theme.spacing(0, 'auto'),
        position: 'relative',
        gridTemplateAreas: "\n        'title title'\n        ".concat(gridRow, "\n        "),
        gridTemplateColumns: bannerAlignment === 'left' ? '9fr 11fr' : '4fr 1fr',
        justifyContent: 'center',
        justifyItems: 'center',
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(0, 'auto'),
            // Larger screens: Apply margin
            display: 'grid',
            flexDirection: 'unset',
            gap: theme.spacing(6, 4),
            gridTemplateColumns: bannerAlignment === 'left' ? '7fr 13fr' : "4fr minmax(auto, ".concat(_constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].sm, "px)"),
            padding: theme.spacing(0, 6)
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: bannerAlignment === 'left' ? '5fr 15fr' : "4fr minmax(auto, ".concat(_constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md, "px)")
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: bannerAlignment === 'left' ? "minmax(auto, ".concat(_constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md, "px) 1fr") : "4fr minmax(auto, ".concat(_constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md, "px)")
        }
    };
});
const Circle = exports.Circle = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        background: theme.palette.purple[100],
        borderRadius: '50%',
        height: 330,
        left: theme.spacing(-33),
        opacity: 0.2,
        position: 'absolute',
        top: 0,
        width: 330,
        zIndex: -1,
        [theme.breakpoints.up('sm')]: {
            height: 392,
            left: 'initial',
            right: -136,
            top: theme.spacing(-8),
            width: 392
        }
    };
});
const BannerWrapper = exports.BannerWrapper = (0, _mui.styled)('div', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme, bannerOrientation } = param;
    return {
        maxWidth: _constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].sm,
        margin: '0 auto',
        height: _constants.BANNER_HEIGHT_BY_ORIENTATION[bannerOrientation].sm,
        position: 'relative',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            height: _constants.BANNER_HEIGHT_BY_ORIENTATION[bannerOrientation].md,
            margin: 0,
            maxWidth: _constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md
        }
    };
});
