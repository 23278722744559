"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = exports.BenefitsList = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('section')((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        background: theme.palette.primary.light,
        boxSizing: 'border-box',
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        padding: theme.spacing(6, 3),
        width: '100%',
        '& *': {
            boxSizing: 'inherit'
        },
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(6)
        }
    };
});
const BenefitsList = exports.BenefitsList = (0, _mui.styled)('ul')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6),
        justifyContent: 'space-evenly',
        maxWidth: 1100,
        padding: theme.spacing(0, 3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            padding: 0
        }
    };
});
