"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.shouldRenderCategoryListing = exports.getCategoryListing = void 0;
var _productBox = require("@ori/product-box");
const shouldRenderCategoryListing = (param)=>{
    let { productBoxes } = param;
    return productBoxes.length > 0;
};
exports.shouldRenderCategoryListing = shouldRenderCategoryListing;
const getCategoryListing = (data, isAnonymousUser)=>{
    var _data_application_frontpage_digitalPromotions, _data_application_frontpage, _data_application, _data_application_frontpage_digitalPromotions1, _data_application_frontpage1, _data_application1;
    var _data_application_frontpage_digitalPromotions_categoryBanners;
    const [banner] = (_data_application_frontpage_digitalPromotions_categoryBanners = (_data_application = data.application) === null || _data_application === void 0 ? void 0 : (_data_application_frontpage = _data_application.frontpage) === null || _data_application_frontpage === void 0 ? void 0 : (_data_application_frontpage_digitalPromotions = _data_application_frontpage.digitalPromotions) === null || _data_application_frontpage_digitalPromotions === void 0 ? void 0 : _data_application_frontpage_digitalPromotions.categoryBanners) !== null && _data_application_frontpage_digitalPromotions_categoryBanners !== void 0 ? _data_application_frontpage_digitalPromotions_categoryBanners : [];
    var _data_application_frontpage_digitalPromotions_categoryProducts;
    const productBoxes = (0, _productBox.mapProductBoxesFromGql)({
        data: [
            ...(_data_application_frontpage_digitalPromotions_categoryProducts = (_data_application1 = data.application) === null || _data_application1 === void 0 ? void 0 : (_data_application_frontpage1 = _data_application1.frontpage) === null || _data_application_frontpage1 === void 0 ? void 0 : (_data_application_frontpage_digitalPromotions1 = _data_application_frontpage1.digitalPromotions) === null || _data_application_frontpage_digitalPromotions1 === void 0 ? void 0 : _data_application_frontpage_digitalPromotions1.categoryProducts) !== null && _data_application_frontpage_digitalPromotions_categoryProducts !== void 0 ? _data_application_frontpage_digitalPromotions_categoryProducts : []
        ],
        isAnonymousUser
    });
    return {
        banner: banner !== null && banner !== void 0 ? banner : null,
        productBoxes,
        shouldRender: shouldRenderCategoryListing({
            productBoxes
        })
    };
};
exports.getCategoryListing = getCategoryListing;
