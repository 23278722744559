"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Top = exports.Root = exports.BannerWrapper = void 0;
var _mui = require("@ori-ui/mui");
var _constants = require("./constants");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        margin: 0,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: _constants.HERO_BANNER_SECTION_MOBILE_HEIGHT,
        [theme.breakpoints.up('sm')]: {
            height: 'auto'
        }
    };
});
const Top = exports.Top = (0, _mui.styled)('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    width: '100%'
});
const BannerWrapper = exports.BannerWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap'
        }
    };
});
