"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.analyticsBannersMapper = void 0;
const analyticsBannersMapper = (banners)=>banners.filter((banner)=>banner !== null).map((param)=>{
        let { headline, imageUrl, link } = param;
        var _link_relativeUrl;
        return {
            localBannerTitle: headline !== null && headline !== void 0 ? headline : '',
            media: imageUrl ? [
                imageUrl
            ] : [],
            target: (_link_relativeUrl = link === null || link === void 0 ? void 0 : link.relativeUrl) !== null && _link_relativeUrl !== void 0 ? _link_relativeUrl : ''
        };
    });
exports.analyticsBannersMapper = analyticsBannersMapper;
