"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment CategoryBanners on CategoryBanner {\n    headline\n    shortText\n    imageUrl\n    fontColor\n    mobileFontColor\n    link {\n      title\n      relativeUrl\n    }\n    tagline\n    validFrom\n    validTo\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CATEGORY_BANNERS_FRAGMENT = void 0;
var _fetching = require("@ori/fetching");
const CATEGORY_BANNERS_FRAGMENT = exports.CATEGORY_BANNERS_FRAGMENT = (0, _fetching.gql)(_templateObject());
