"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useGetNotificationBanner = void 0;
var _contexts = require("../../contexts");
const useGetNotificationBanner = ()=>{
    const { notificationBanner } = (0, _contexts.useFrontpage)();
    return {
        notificationBanner
    };
};
exports.useGetNotificationBanner = useGetNotificationBanner;
