"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
var _constants = require("./constants");
const Root = exports.Root = (0, _mui.styled)('img')((param)=>{
    let { theme } = param;
    return {
        borderRadius: '50%',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        maxHeight: _constants.BRAND_MOBILE_WIDTH,
        maxWidth: _constants.BRAND_MOBILE_WIDTH,
        [theme.breakpoints.up('sm')]: {
            maxHeight: _constants.BRAND_DESKTOP_WIDTH,
            maxWidth: _constants.BRAND_DESKTOP_WIDTH
        }
    };
});
