"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useBenefitBannerData = void 0;
var _auth = require("@ori/auth");
var _environment = require("@ori/environment");
var _featureToggles = require("@ori/feature-toggles");
var _i18n = require("@ori/i18n");
var _presentationHttp = require("@ori/presentation-http");
var _staticApiClientReact = require("@ori/static-api-client-react");
var _constants = require("../constants");
var _contexts = require("../contexts");
var _net = require("../models/graphql-types/net");
// istanbul ignore file -- Will be replaced with TopAreaBanner
const useBenefitBannerData = (param)=>{
    let { isBenefitBannerHiddenCookie } = param;
    const { isAnonymousUser } = (0, _auth.useToken)();
    const { tenant } = (0, _environment.useEnvironment)();
    const { language } = (0, _i18n.useLanguage)();
    const { visitorSegment, loading } = (0, _presentationHttp.useUserSegmentationData)();
    const { isFeatureEnabled } = (0, _featureToggles.useFeatureToggles)();
    const skip = loading || isAnonymousUser || isBenefitBannerHiddenCookie || isFeatureEnabled(_constants.HeroBannerFeature.EnableTopAreaBanner);
    const { data, error } = (0, _staticApiClientReact.useGetTenantApplicationsFrontPageBenefitBanner)(tenant, {
        lang: language,
        visitorSegment: visitorSegment !== null && visitorSegment !== void 0 ? visitorSegment : _net.VisitorSegment.Anonymous
    }, {
        swr: {
            enabled: !skip,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    });
    const { benefitBanner } = (0, _contexts.useFrontpage)();
    return {
        data: {
            messageTitle: isAnonymousUser ? benefitBanner === null || benefitBanner === void 0 ? void 0 : benefitBanner.messageTitle : data === null || data === void 0 ? void 0 : data.messageTitle,
            messageUrl: isAnonymousUser ? benefitBanner === null || benefitBanner === void 0 ? void 0 : benefitBanner.messageUrl : data === null || data === void 0 ? void 0 : data.messageUrl
        },
        loading: !skip && data === undefined && error === undefined
    };
};
exports.useBenefitBannerData = useBenefitBannerData;
