"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Title = exports.Root = exports.Description = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        color: theme.palette.text.primary,
        fontSize: '4rem',
        maxWidth: 1346,
        alignItems: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        order: 0,
        gridArea: 'title',
        gap: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column'
    };
});
const Description = exports.Description = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        minHeight: 45,
        textAlign: 'center',
        gridArea: 'title',
        maxWidth: 600,
        width: '100%',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 350
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 500
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 600
        }
    };
});
const Title = exports.Title = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        minWidth: '70%',
        [theme.breakpoints.between('md', 1367)]: {
            marginLeft: theme.spacing(4)
        }
    };
});
