"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Video = exports.Root = void 0;
var _mui = require("@ori-ui/mui");
var _presentationComponents = require("@ori/presentation-components");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBlockEnd: theme.spacing(4),
        padding: theme.spacing(10, 0),
        [theme.breakpoints.up('sm')]: {
            marginBlockEnd: theme.spacing(12)
        }
    };
});
const Video = exports.Video = (0, _mui.styled)(_presentationComponents.Video)({
    maxWidth: '800px',
    width: '100%'
});
