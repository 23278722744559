"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _CampaignBannerSectionSkeleton = require("./CampaignBannerSectionSkeleton");
Object.keys(_CampaignBannerSectionSkeleton).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _CampaignBannerSectionSkeleton[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _CampaignBannerSectionSkeleton[key];
        }
    });
});
var _constants = require("./constants");
Object.keys(_constants).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _constants[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _constants[key];
        }
    });
});
