"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.setCookie = exports.getCookie = void 0;
const setCookie = (cookieName, cookieValue, expirationDays)=>{
    if (expirationDays === undefined) {
        document.cookie = "".concat(cookieName, "=").concat(cookieValue, ";path=/");
        return;
    }
    const actualTimeInMiliseconds = Date.now();
    const expirationDaysInMilliSeconds = expirationDays * 24 * 60 * 60 * 1000;
    const expirationTime = new Date(actualTimeInMiliseconds + expirationDaysInMilliSeconds);
    document.cookie = "".concat(cookieName, "=").concat(cookieValue, ";expires=").concat(expirationTime.toUTCString(), ";path=/");
};
exports.setCookie = setCookie;
const getCookie = (cookieName)=>{
    var _parts_pop;
    const value = "; ".concat(document.cookie);
    const parts = value.split("; ".concat(cookieName, "="));
    return parts.length === 2 ? (_parts_pop = parts.pop()) === null || _parts_pop === void 0 ? void 0 : _parts_pop.split(';').shift() : null;
};
exports.getCookie = getCookie;
