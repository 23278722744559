"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getHeroBanners = void 0;
const getHeroBanners = (banners)=>{
    if (!banners) {
        return [];
    }
    return banners.filter((banner)=>banner !== null).sort((bannerA, bannerB)=>{
        var _bannerA_priority, _bannerB_priority;
        return ((_bannerA_priority = bannerA.priority) !== null && _bannerA_priority !== void 0 ? _bannerA_priority : 0) - ((_bannerB_priority = bannerB.priority) !== null && _bannerB_priority !== void 0 ? _bannerB_priority : 0);
    }).slice(0, 3);
};
exports.getHeroBanners = getHeroBanners;
