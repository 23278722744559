"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _getFrontpageData = require("./getFrontpageData");
Object.keys(_getFrontpageData).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getFrontpageData[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getFrontpageData[key];
        }
    });
});
