"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
var _constants = require("./constants");
const Root = exports.Root = (0, _mui.styled)('img')((param)=>{
    let { theme } = param;
    return {
        height: _constants.VIP_BENEFIT_ICON_MOBILE_SIZE,
        padding: 6,
        width: _constants.VIP_BENEFIT_ICON_MOBILE_SIZE,
        [theme.breakpoints.up('sm')]: {
            height: _constants.VIP_BENEFIT_ICON_DESKTOP_SIZE,
            padding: 7,
            width: _constants.VIP_BENEFIT_ICON_DESKTOP_SIZE
        }
    };
});
