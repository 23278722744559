"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Title = exports.StyledButton = exports.Root = exports.Description = exports.ButtonWrapper = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        alignContent: 'center',
        background: (0, _mui.alpha)(theme.palette.grey[100], 0.6),
        color: theme.palette.text.primary,
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(6, 3),
        textAlign: 'center',
        borderRadius: theme.spacing(0, 1, 1, 0),
        gap: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            background: theme.palette.grey[100],
            flex: 1,
            padding: theme.spacing(6, 16, 6, 16),
            textAlign: 'left'
        }
    };
});
const Title = exports.Title = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        fontSize: '3rem',
        textAlign: 'center',
        lineHeight: 1.235,
        [theme.breakpoints.up('md')]: {
            justifySelf: 'center',
            textAlign: 'center',
            fontSize: '3rem',
            lineHeight: 1.35
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '4rem',
            textAlign: 'left'
        }
    };
});
const Description = exports.Description = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        justifySelf: 'center',
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            justifySelf: 'start',
            textAlign: 'start'
        }
    };
});
const ButtonWrapper = exports.ButtonWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        justifySelf: 'center',
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            justifySelf: 'start',
            textAlign: 'start'
        }
    };
});
const StyledButton = exports.StyledButton = (0, _mui.styled)(_mui.Button)({
    height: '45px',
    textTransform: 'uppercase'
});
