"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.UnifiedSkeleton = exports.Root = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBlockEnd: theme.spacing(12),
        [theme.breakpoints.up('sm')]: {
            marginBlockEnd: theme.spacing(20)
        }
    };
});
const UnifiedSkeleton = exports.UnifiedSkeleton = (0, _mui.styled)(_mui.Skeleton)({
    transform: 'none'
});
