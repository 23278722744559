"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.createSessionStoreRepository = void 0;
var _presentationUtils = require("@ori/presentation-utils");
var _ServerSessionStorage = require("./ServerSessionStorage");
var _SessionStoreRepository = require("./SessionStoreRepository");
const createSessionStoreRepository = ()=>{
    if ((0, _presentationUtils.isSSR)()) {
        return new _SessionStoreRepository.SessionStoreRepository(new _ServerSessionStorage.ServerSessionStorage());
    }
    return new _SessionStoreRepository.SessionStoreRepository(window.sessionStorage);
};
exports.createSessionStoreRepository = createSessionStoreRepository;
