"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useImagesInfo = void 0;
var _mui = require("@ori-ui/mui");
var _imageSizesHelper = require("@ori/image-sizes-helper");
var _react = require("react");
const useImagesInfo = (imageUrl, smallImageUrl)=>{
    const theme = (0, _mui.useTheme)();
    return (0, _react.useMemo)(()=>{
        if (!imageUrl) {
            return [];
        }
        const { sm, md, lg, xl } = theme.breakpoints.values;
        return [
            ...(0, _imageSizesHelper.getImageVariants)({
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- We want to filter-out empty strings as well
                url: smallImageUrl || imageUrl,
                backgroundColor: theme.palette.grey[100],
                imageFormat: 'WebP',
                imageSizeMultiplier: 1.5,
                params: [
                    sm,
                    md
                ].map((v)=>({
                        width: v,
                        quality: 90
                    }))
            }),
            ...(0, _imageSizesHelper.getImageVariants)({
                url: imageUrl,
                backgroundColor: theme.palette.grey[100],
                imageFormat: 'WebP',
                imageSizeMultiplier: 1.5,
                params: [
                    lg,
                    xl,
                    1920,
                    3840
                ].map((v)=>({
                        width: v,
                        quality: 90
                    }))
            })
        ].map((param, i, arr)=>{
            let { url, width } = param;
            var _arr_;
            var _arr__width;
            return {
                url,
                media: [
                    "(min-width: ".concat((_arr__width = (_arr_ = arr[i - 1]) === null || _arr_ === void 0 ? void 0 : _arr_.width) !== null && _arr__width !== void 0 ? _arr__width : 0, "px)"),
                    i === arr.length - 1 ? '' : "(max-width: ".concat(width - 0.01, "px)")
                ].filter((v)=>v).join(' and ')
            };
        });
    }, [
        imageUrl,
        smallImageUrl,
        theme.breakpoints.values,
        theme.palette.grey
    ]);
};
exports.useImagesInfo = useImagesInfo;
