"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment TopBrands on Frontpage {\n    topBrands {\n      brands {\n        name\n        logo {\n          url\n        }\n        url\n      }\n      title\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TOP_BRANDS_FRAGMENT = void 0;
var _fetching = require("@ori/fetching");
const TOP_BRANDS_FRAGMENT = exports.TOP_BRANDS_FRAGMENT = (0, _fetching.gql)(_templateObject());
