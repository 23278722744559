"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TitleSkeleton = exports.DescriptionWrapper = exports.CaptionSkeleton = void 0;
var _mui = require("@ori-ui/mui");
var _styles = require("../../../styles");
const TitleSkeleton = exports.TitleSkeleton = (0, _mui.styled)(_styles.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        width: '150px',
        height: '35px',
        [theme.breakpoints.up('sm')]: {
            width: '240px',
            height: '48px'
        }
    };
});
const DescriptionWrapper = exports.DescriptionWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        maxWidth: 600,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2)
    };
});
const CaptionSkeleton = exports.CaptionSkeleton = (0, _mui.styled)(_styles.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        height: '1.4rem'
    };
});
