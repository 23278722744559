"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _CategoryListing = require("./CategoryListing");
Object.keys(_CategoryListing).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _CategoryListing[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _CategoryListing[key];
        }
    });
});
var _beautyVideo = require("./beautyVideo");
Object.keys(_beautyVideo).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _beautyVideo[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _beautyVideo[key];
        }
    });
});
var _bestOffers = require("./bestOffers");
Object.keys(_bestOffers).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _bestOffers[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _bestOffers[key];
        }
    });
});
var _campaignBanner = require("./campaignBanner");
Object.keys(_campaignBanner).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _campaignBanner[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _campaignBanner[key];
        }
    });
});
var _categoryNavigation = require("./categoryNavigation");
Object.keys(_categoryNavigation).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _categoryNavigation[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _categoryNavigation[key];
        }
    });
});
var _frontPageQuery = require("./frontPageQuery");
Object.keys(_frontPageQuery).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _frontPageQuery[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _frontPageQuery[key];
        }
    });
});
var _heroBannerQuery = require("./heroBannerQuery");
Object.keys(_heroBannerQuery).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _heroBannerQuery[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _heroBannerQuery[key];
        }
    });
});
var _motivationContent = require("./motivationContent");
Object.keys(_motivationContent).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _motivationContent[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _motivationContent[key];
        }
    });
});
var _topBrands = require("./topBrands");
Object.keys(_topBrands).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _topBrands[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _topBrands[key];
        }
    });
});
var _vipBenefits = require("./vipBenefits");
Object.keys(_vipBenefits).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _vipBenefits[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _vipBenefits[key];
        }
    });
});
var _whatsNew = require("./whatsNew");
Object.keys(_whatsNew).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _whatsNew[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _whatsNew[key];
        }
    });
});
var _notificationBannerQuery = require("./notificationBannerQuery");
Object.keys(_notificationBannerQuery).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _notificationBannerQuery[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _notificationBannerQuery[key];
        }
    });
});
var _iconicProducts = require("./iconicProducts");
Object.keys(_iconicProducts).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _iconicProducts[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _iconicProducts[key];
        }
    });
});
