"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TextBox = exports.TagLine = exports.StyledButton = exports.StyledBanner = exports.Root = exports.MobileImagePlaceholderWrapper = exports.Image = exports.Headline = exports.DesktopImagePlaceholderWrapper = exports.CarouselSlide = exports.ButtonWrapper = void 0;
var _mui = require("@ori-ui/mui");
var _presentationComponents = require("@ori/presentation-components");
var _presentationUtils = require("@ori/presentation-utils");
var _constants = require("../../containers/HeroBannerSection/constants");
const Root = exports.Root = (0, _mui.styled)('div', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme } = param;
    return {
        background: theme.palette.grey.A400,
        margin: 'auto',
        maxHeight: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        position: 'relative'
    };
});
const CarouselSlide = exports.CarouselSlide = (0, _mui.styled)(_presentationComponents.CarouselSlide)({
    '&:first-of-type': {
        a: {
            width: '100vw'
        }
    }
});
const StyledBanner = exports.StyledBanner = (0, _mui.styled)('a')((param)=>{
    let { theme } = param;
    return {
        color: theme.palette.common.white,
        display: 'block',
        height: _constants.HERO_BANNER_SECTION_MOBILE_HEIGHT,
        '&:hover, &:focus, &:active': {
            color: theme.palette.common.white
        },
        [theme.breakpoints.up('sm')]: {
            height: '500px'
        }
    };
});
const TextBox = exports.TextBox = (0, _mui.styled)('div', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme, desktopColor, mobileColor } = param;
    return {
        alignItems: 'center',
        bottom: theme.spacing(11),
        color: mobileColor,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        justifyContent: 'center',
        margin: theme.spacing(0, 6),
        position: 'absolute',
        width: "calc(100% - ".concat(theme.spacing(12), ")"),
        [theme.breakpoints.up('sm')]: {
            bottom: theme.spacing(12),
            color: desktopColor
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-end',
            bottom: '50%',
            margin: 0,
            maxWidth: 1186,
            padding: theme.spacing(0, 18),
            right: '50%',
            transform: 'translate(50%, 50%)',
            width: "calc(100% - ".concat(theme.spacing(36), ")")
        }
    };
});
const TagLine = exports.TagLine = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        color: 'inherit',
        display: '-webkit-box',
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 3,
        maxWidth: 450,
        overflow: 'hidden',
        textAlign: 'center',
        textTransform: 'uppercase',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        [theme.breakpoints.up('md')]: {
            width: 450
        }
    };
});
const Headline = exports.Headline = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        color: 'inherit',
        display: '-webkit-box',
        fontSize: '3rem',
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '4.2rem',
        maxWidth: 450,
        overflow: 'hidden',
        textAlign: 'center',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        [theme.breakpoints.up('md')]: {
            fontSize: '5rem',
            lineHeight: '7rem',
            width: 450
        }
    };
});
const ButtonWrapper = exports.ButtonWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 450
        }
    };
});
const StyledButton = exports.StyledButton = (0, _mui.styled)(_mui.Button)((param)=>{
    let { theme } = param;
    return {
        alignSelf: 'center',
        color: theme.palette.common.white,
        height: 45,
        maxWidth: 450,
        minWidth: 150
    };
});
const Image = exports.Image = (0, _mui.styled)('img', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme } = param;
    return {
        backgroundColor: theme.palette.primary.light,
        position: 'absolute',
        objectFit: 'cover',
        width: '100%',
        height: '100%'
    };
});
const DesktopImagePlaceholderWrapper = exports.DesktopImagePlaceholderWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'none',
        height: 500,
        position: 'relative',
        svg: {
            objectFit: 'cover',
            position: 'absolute',
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    };
});
const MobileImagePlaceholderWrapper = exports.MobileImagePlaceholderWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'block',
        height: 'calc(100vh - 214px)',
        position: 'relative',
        svg: {
            objectFit: 'cover',
            position: 'absolute',
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    };
});
