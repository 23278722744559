"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getFrontpageData = void 0;
var _fetching = require("@ori/fetching");
var _productBox = require("@ori/product-box");
var _staticApiClientReact = require("@ori/static-api-client-react");
var _queriesNet = require("../queries-net");
/* istanbul ignore file -- api */ /**
 * Fetch function that fetches data for the frontpage.
 */ const getFrontpageData = async (param)=>{
    let { graphQlNetUrl, staticApiUrl, headers, tenant } = param;
    const staticApiClient = new _staticApiClientReact.ApiClient({
        baseUrl: staticApiUrl,
        tenant
    });
    const [data, hideRating, { data: productBoxConfiguration }] = await Promise.all([
        (0, _fetching.fetchGraphql)({
            query: _queriesNet.frontPageQuery,
            url: graphQlNetUrl,
            headers
        }),
        (0, _productBox.resolveHideRating)({
            staticApiUrl,
            tenant
        }),
        (0, _staticApiClientReact.getTenantApplicationsProductboxConfiguration)(staticApiClient, tenant)
    ]);
    return {
        data,
        productBoxConfiguration: {
            ...productBoxConfiguration,
            isRatingsAndReviewsEnabled: productBoxConfiguration.isRatingsAndReviewsEnabled || !hideRating
        }
    };
};
exports.getFrontpageData = getFrontpageData;
