"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.shouldRenderTopBrands = exports.getTopBrands = exports.getBrands = void 0;
const getBrands = (brands)=>(brands !== null && brands !== void 0 ? brands : []).map((brand)=>{
        var _brand_logo_, _brand_logo;
        var _brand_logo__url, _brand_name, _brand_url;
        return {
            imageUrl: (_brand_logo__url = brand === null || brand === void 0 ? void 0 : (_brand_logo = brand.logo) === null || _brand_logo === void 0 ? void 0 : (_brand_logo_ = _brand_logo[0]) === null || _brand_logo_ === void 0 ? void 0 : _brand_logo_.url) !== null && _brand_logo__url !== void 0 ? _brand_logo__url : '',
            name: (_brand_name = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _brand_name !== void 0 ? _brand_name : '',
            url: (_brand_url = brand === null || brand === void 0 ? void 0 : brand.url) !== null && _brand_url !== void 0 ? _brand_url : ''
        };
    }).filter((brand)=>!!brand.imageUrl).slice(0, 4);
exports.getBrands = getBrands;
const shouldRenderTopBrands = (param)=>{
    let { brands } = param;
    return brands.length > 0;
};
exports.shouldRenderTopBrands = shouldRenderTopBrands;
const getTopBrands = (data)=>{
    var _data_title;
    const parsedData = {
        brands: getBrands(data === null || data === void 0 ? void 0 : data.brands),
        title: (_data_title = data === null || data === void 0 ? void 0 : data.title) !== null && _data_title !== void 0 ? _data_title : ''
    };
    return {
        ...parsedData,
        shouldRender: shouldRenderTopBrands(parsedData)
    };
};
exports.getTopBrands = getTopBrands;
