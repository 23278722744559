"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  query GetFrontPage($validityDate: Date) {\n    application {\n      frontpage {\n        ...MotivationContent\n        ...TopBrands\n        ...BeautyVideo\n        ...VipBenefits\n        ...CategoryNavigation\n        ...BenefitBanner\n        ...NotificationBanner\n        ...BestsellerProducts\n        ...IconicProducts\n        digitalPromotions(validityDate: $validityDate, channel: Website) {\n          heroBanners {\n            ...HeroBanner\n          }\n          bestOffersBanners {\n            ...BestOffersBanners\n          }\n          bestOffersProducts {\n            ...ProductWithShades\n          }\n          whatsNewBanners {\n            ...WhatsNewBanners\n          }\n          whatsNewProducts {\n            ...ProductWithShades\n          }\n          categoryBanners {\n            ...CategoryBanners\n          }\n          categoryProducts {\n            ...ProductWithShades\n          }\n          campaignBanners {\n            ...CampaignBanners\n          }\n        }\n      }\n    }\n    ...VipCustomerBanner\n  }\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.frontPageQuery = void 0;
var _fetching = require("@ori/fetching");
var _productBox = require("@ori/product-box");
var _vipCustomerBanner = require("@ori/vip-customer-banner");
var _CategoryListing = require("./CategoryListing");
var _beautyVideo = require("./beautyVideo");
var _benefitBannerQuery = require("./benefitBannerQuery");
var _bestOffers = require("./bestOffers");
var _bestSellerProducts = require("./bestSellerProducts");
var _campaignBanner = require("./campaignBanner");
var _categoryNavigation = require("./categoryNavigation");
var _heroBannerQuery = require("./heroBannerQuery");
var _iconicProducts = require("./iconicProducts");
var _motivationContent = require("./motivationContent");
var _notificationBannerQuery = require("./notificationBannerQuery");
var _productPromotionBanner = require("./productPromotionBanner");
var _topBrands = require("./topBrands");
var _vipBenefits = require("./vipBenefits");
var _whatsNew = require("./whatsNew");
const frontPageQuery = exports.frontPageQuery = (0, _fetching.gql)(_templateObject(), _motivationContent.MOTIVATION_CONTENT_FRAGMENT, _topBrands.TOP_BRANDS_FRAGMENT, _beautyVideo.BEAUTY_VIDEO_FRAGMENT, _vipBenefits.VIP_BENEFITS_FRAGMENT, _categoryNavigation.CATEGORY_NAVIGATION_FRAGMENT, _benefitBannerQuery.BENEFIT_BANNER_FRAGMENT, _heroBannerQuery.HERO_BANNER_FRAGMENT, _bestOffers.BEST_OFFERS_BANNERS_FRAGMENT, _whatsNew.WHATS_NEW_BANNERS_FRAGMENT, _CategoryListing.CATEGORY_BANNERS_FRAGMENT, _campaignBanner.CAMPAIGN_BANNERS_FRAGMENT, _vipCustomerBanner.VIP_CUSTOMER_BANNER_FRAGMENT, _productBox.PRODUCT_WITH_SHADES_FRAGMENT, _notificationBannerQuery.NOTIFICATION_BANNER_FRAGMENT, _bestSellerProducts.BESTSELLER_PRODUCTS_FRAGMENT, _iconicProducts.ICONIC_PRODUCTS_FRAGMENT, _productPromotionBanner.PRODUCT_PROMOTION_BANNER_FRAGMENT);
