"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Wrapper = exports.TitleWrapper = exports.StyledContent = exports.Root = exports.Line = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        padding: theme.spacing(10, 3)
    };
});
const TitleWrapper = exports.TitleWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        maxWidth: '345px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px'
        }
    };
});
const Wrapper = exports.Wrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(6)
        }
    };
});
const StyledContent = exports.StyledContent = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        maxWidth: '100%',
        padding: theme.spacing(0, 3),
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px',
            padding: 0
        }
    };
});
const Line = exports.Line = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        border: "2px solid ".concat(theme.palette.green[200]),
        borderRadius: theme.spacing(1),
        height: '0px',
        opacity: 1,
        width: '86px'
    };
});
