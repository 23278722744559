"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.VideoSkeleton = exports.Root = exports.ButtonSkeleton = void 0;
var _mui = require("@ori-ui/mui");
var _components = require("../../components");
var _styles = require("../BeautyVideoSection/styles");
const Root = exports.Root = (0, _mui.styled)(_styles.Root)({
    alignItems: 'center'
});
const VideoSkeleton = exports.VideoSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        width: '100%',
        aspectRatio: '3.5 / 2',
        [theme.breakpoints.up('sm')]: {
            width: '800px'
        }
    };
});
const ButtonSkeleton = exports.ButtonSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        borderRadius: theme.spacing(5),
        marginTop: theme.spacing(6),
        width: '266px',
        height: '45px'
    };
});
