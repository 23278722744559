"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.shouldRenderVipBenefits = exports.getVipBenefits = void 0;
const shouldRenderVipBenefits = (param)=>{
    let { images, linkRelativeUrl, linkTitle } = param;
    return images.length > 0 && !!linkRelativeUrl && !!linkTitle;
};
exports.shouldRenderVipBenefits = shouldRenderVipBenefits;
const getVipBenefits = (data)=>{
    var _data_link, _data_link1;
    var _data_images, _data_link_relativeUrl, _data_link_title;
    const parsedData = {
        images: ((_data_images = data === null || data === void 0 ? void 0 : data.images) !== null && _data_images !== void 0 ? _data_images : []).filter((image)=>!!(image === null || image === void 0 ? void 0 : image.subTitle) && !!image.title && !!image.url),
        linkRelativeUrl: (_data_link_relativeUrl = data === null || data === void 0 ? void 0 : (_data_link = data.link) === null || _data_link === void 0 ? void 0 : _data_link.relativeUrl) !== null && _data_link_relativeUrl !== void 0 ? _data_link_relativeUrl : '',
        linkTitle: (_data_link_title = data === null || data === void 0 ? void 0 : (_data_link1 = data.link) === null || _data_link1 === void 0 ? void 0 : _data_link1.title) !== null && _data_link_title !== void 0 ? _data_link_title : ''
    };
    return {
        ...parsedData,
        shouldRender: shouldRenderVipBenefits(parsedData)
    };
};
exports.getVipBenefits = getVipBenefits;
