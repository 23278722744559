"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Text = exports.Root = exports.InnerRoot = exports.Headline = exports.DetailsRoot = exports.ButtonSkeleton = void 0;
var _mui = require("@ori-ui/mui");
var _components = require("../../components");
var _constants = require("../../constants");
var _constants2 = require("../CampaignBannerSection/constants");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        marginBlockEnd: theme.spacing(20),
        overflow: 'hidden',
        padding: theme.spacing(8, 0),
        width: '100%',
        '& *': {
            boxSizing: 'inherit'
        },
        [theme.breakpoints.up('sm')]: {
            overflow: 'visible'
        }
    };
});
const InnerRoot = exports.InnerRoot = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'grid',
        justifyContent: 'center',
        position: 'relative',
        maxWidth: _constants.SECTION_MAX_WIDTH,
        width: '100%',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '250px 238px',
        [theme.breakpoints.up(_constants2.TABLET_BANNER_BREAKPOINT)]: {
            gridTemplateColumns: '0.5fr 0.5fr',
            gridTemplateRows: '434px'
        }
    };
});
const ButtonSkeleton = exports.ButtonSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        justifySelf: 'center',
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(6),
        textTransform: 'uppercase',
        borderRadius: theme.spacing(5),
        [theme.breakpoints.up(_constants2.TABLET_BANNER_BREAKPOINT)]: {
            marginBottom: 0,
            justifySelf: 'start'
        }
    };
});
const DetailsRoot = exports.DetailsRoot = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'grid',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        gridRow: 1,
        textAlign: 'center',
        [theme.breakpoints.up(_constants2.TABLET_BANNER_BREAKPOINT)]: {
            gridTemplateColumns: '73%',
            textAlign: 'start',
            gridRow: 'auto'
        }
    };
});
const Headline = exports.Headline = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: 0
        }
    };
});
const Text = exports.Text = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        height: '19px',
        marginTop: theme.spacing(2)
    };
});
