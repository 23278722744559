"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useBannerVisibilityToggle = exports.NOTIFICATION_BANNER_STORAGE_KEY = void 0;
var _mui = require("@ori-ui/mui");
var _presentationUtils = require("@ori/presentation-utils");
var _hooks = require("../../hooks");
const NOTIFICATION_BANNER_STORAGE_KEY = exports.NOTIFICATION_BANNER_STORAGE_KEY = 'showNotificationBanner';
const useBannerVisibilityToggle = ()=>{
    const { value: showBanner, setValue: setShowBanner } = (0, _hooks.useSessionStorage)(NOTIFICATION_BANNER_STORAGE_KEY, !(0, _presentationUtils.isSSR)());
    const toggleBannerVisibility = (0, _mui.useEventCallback)(()=>setShowBanner((val)=>!val));
    return {
        showBanner,
        toggleBannerVisibility
    };
};
exports.useBannerVisibilityToggle = useBannerVisibilityToggle;
