"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _useBenefitBannerData = require("./useBenefitBannerData");
Object.keys(_useBenefitBannerData).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useBenefitBannerData[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useBenefitBannerData[key];
        }
    });
});
var _useTranslations = require("./useTranslations");
Object.keys(_useTranslations).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useTranslations[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useTranslations[key];
        }
    });
});
var _useVipCustomerBannerData = require("./useVipCustomerBannerData");
Object.keys(_useVipCustomerBannerData).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useVipCustomerBannerData[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useVipCustomerBannerData[key];
        }
    });
});
var _useSessionStorage = require("./useSessionStorage");
Object.keys(_useSessionStorage).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useSessionStorage[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useSessionStorage[key];
        }
    });
});
var _useInstance = require("./useInstance");
Object.keys(_useInstance).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useInstance[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useInstance[key];
        }
    });
});
