"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Text = exports.Root = exports.ImageWrapper = exports.ImagePlaceholder = exports.Image = void 0;
var _mui = require("@ori-ui/mui");
var _presentationUtils = require("@ori/presentation-utils");
var _constants = require("../../containers/CampaignBannerSection/constants");
var _constants2 = require("./constants");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        [theme.breakpoints.up(_constants.TABLET_BANNER_BREAKPOINT)]: {
            flex: "".concat(_constants2.CAMPAIGN_BANNER_IMAGE_DESKTOP_WIDTH, "px 0 0")
        }
    };
});
const Text = exports.Text = (0, _mui.styled)(_mui.Typography, {
    shouldForwardProp: (0, _presentationUtils.shouldNotForwardProp)([
        'mobileColor',
        'desktopColor'
    ])
})((param)=>{
    let { desktopColor, mobileColor, theme } = param;
    return {
        alignSelf: 'flex-end',
        color: mobileColor,
        fontFamily: 'SansOri, SansOriFallback, sans-serif',
        marginBottom: theme.spacing(3),
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        fontSize: '2.5rem',
        [theme.breakpoints.up('sm')]: {
            color: desktopColor,
            marginBottom: theme.spacing(8)
        }
    };
});
const getImageStyles = (theme)=>({
        borderRadius: theme.spacing(0),
        height: '100%',
        objectFit: 'cover',
        objectPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            borderRadius: theme.spacing(1)
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: theme.spacing(1, 0, 0, 1)
        }
    });
const Image = exports.Image = (0, _mui.styled)('img')((param)=>{
    let { theme } = param;
    return {
        ...getImageStyles(theme)
    };
});
const ImagePlaceholder = exports.ImagePlaceholder = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        ...getImageStyles(theme),
        zIndex: -1,
        svg: {
            height: '100%',
            objectFit: 'cover',
            width: '100%',
            [theme.breakpoints.up('md')]: {
                borderRadius: theme.spacing(1)
            },
            [theme.breakpoints.up('lg')]: {
                borderRadius: theme.spacing(1, 0, 0, 1)
            }
        },
        left: 0
    };
});
const ImageWrapper = exports.ImageWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        position: 'relative',
        width: '100%',
        height: 225,
        [theme.breakpoints.up('sm')]: {
            height: 410
        }
    };
});
