"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ViewMoreButtonSkeleton = exports.ProductsListWrapper = void 0;
var _mui = require("@ori-ui/mui");
var _presentationUtils = require("@ori/presentation-utils");
var _styles = require("../styles");
const ViewMoreButtonSkeleton = exports.ViewMoreButtonSkeleton = (0, _mui.styled)(_styles.UnifiedSkeleton, {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme, alignment } = param;
    return {
        borderRadius: theme.spacing(5),
        fontSize: '1.4rem',
        height: '45px',
        textAlign: 'center',
        gridArea: 'title',
        alignSelf: 'center',
        justifySelf: 'center',
        margin: theme.spacing(1, 'auto'),
        order: 4,
        width: '133px',
        [theme.breakpoints.up('sm')]: {
            alignSelf: 'end',
            justifySelf: alignment === 'left' ? 'start' : 'end',
            margin: 0,
            order: 'unset'
        }
    };
});
const ProductsListWrapper = exports.ProductsListWrapper = (0, _mui.styled)('div', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme, maxProductsToDisplayMobile, maxProductsToDisplay } = param;
    return {
        gridArea: 'products',
        display: 'grid',
        gap: theme.spacing(3, 2),
        gridTemplateColumns: "repeat(2, 1fr)",
        listStyle: 'none',
        width: '100%',
        ["& >:nth-of-type(n + ".concat(maxProductsToDisplayMobile + 1, ")")]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: "repeat(".concat(maxProductsToDisplay, ", 1fr)"),
            ["& >:nth-of-type(n + ".concat(maxProductsToDisplayMobile + 1, ")")]: {
                display: 'block'
            }
        }
    };
});
