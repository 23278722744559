"use strict";
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.SessionStoreRepository = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
class SessionStoreRepository {
    get(name) {
        const item = this.storage.getItem(name);
        if (item === null) {
            return null;
        }
        return this.deserialize(item);
    }
    getOrCreate(name, defaultValue) {
        const item = this.get(name);
        if (item !== null) {
            return item;
        }
        return this.save(name, defaultValue);
    }
    save(name, value) {
        this.storage.setItem(name, this.serialize(value));
        return value;
    }
    has(name) {
        return this.get(name) !== null;
    }
    delete(name) {
        this.storage.removeItem(name);
        return this;
    }
    serialize(data) {
        return JSON.stringify(data);
    }
    deserialize(data) {
        try {
            return JSON.parse(data);
        } catch (error) {
            return null;
        }
    }
    constructor(storage){
        (0, _defineProperty2.default)(this, "storage", void 0);
        this.storage = storage;
    }
}
exports.SessionStoreRepository = SessionStoreRepository;
