"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
var _presentationUtils = require("@ori/presentation-utils");
/* eslint-disable @typescript-eslint/naming-convention -- Needed. */ const Root = exports.Root = (0, _mui.styled)('ul', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme, productCount } = param;
    return {
        gridArea: 'products',
        display: 'grid',
        gap: theme.spacing(3, 2),
        gridTemplateColumns: "repeat(2, 1fr)",
        listStyle: 'none',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            'li:nth-child(3)': {
                display: 'none'
            },
            'li:nth-child(4)': {
                display: 'none'
            }
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: "repeat(3, 1fr)",
            'li:nth-child(3)': {
                display: 'list-item'
            }
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: "repeat(".concat(productCount, ", 1fr)"),
            'li:nth-child(4)': {
                display: 'list-item'
            }
        }
    };
});
