"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = exports.Circle = exports.Banner = void 0;
var _mui = require("@ori-ui/mui");
var _constants = require("../../constants");
var _constants2 = require("./constants");
const Root = exports.Root = (0, _mui.styled)('section')((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& *': {
            boxSizing: 'inherit'
        }
    };
});
const Banner = exports.Banner = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        flexFlow: 'column-reverse wrap',
        justifyContent: 'center',
        maxWidth: _constants.SECTION_MAX_WIDTH,
        position: 'relative',
        width: '100%',
        [theme.breakpoints.up(_constants2.TABLET_BANNER_BREAKPOINT)]: {
            flexFlow: 'row wrap'
        }
    };
});
const Circle = exports.Circle = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        background: theme.palette.purple[100],
        borderRadius: 258,
        height: '235px',
        left: '104%',
        opacity: 0.2,
        position: 'absolute',
        top: '-15%',
        transform: 'translateX(-50%)',
        width: '235px',
        zIndex: -1,
        [theme.breakpoints.up('sm')]: {
            height: '478px',
            left: theme.spacing(-12),
            top: '21%',
            width: '478px'
        },
        [theme.breakpoints.up('xl')]: {
            left: theme.spacing(-4)
        }
    };
});
