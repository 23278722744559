"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
var _styles = require("../Banner/styles");
const Root = exports.Root = (0, _mui.styled)(_styles.Root)((param)=>{
    let { theme } = param;
    return {
        paddingTop: 0,
        zIndex: -1,
        svg: {
            height: '100%',
            objectFit: 'cover',
            width: '100%'
        },
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            left: 0,
            transform: 'none'
        }
    };
});
