"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.StyledButton = exports.StyledBannerImage = exports.Root = exports.Link = exports.HeadlineTypography = exports.Content = void 0;
var _mui = require("@ori-ui/mui");
var _presentationUtils = require("@ori/presentation-utils");
var _constants = require("./constants");
const Root = exports.Root = (0, _mui.styled)('aside', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { theme, alignment, bannerOrientation } = param;
    return {
        alignSelf: 'stretch',
        borderRadius: theme.spacing(1),
        gridArea: 'banner',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        order: 0,
        '&:hover': {
            '& $image': {
                filter: 'brightness(0.7)'
            },
            '& $headline': {
                color: theme.palette.common.white
            },
            '& $button': {
                backgroundColor: (0, _mui.alpha)(theme.palette.common.white, 0.8),
                color: theme.palette.common.white
            }
        },
        maxWidth: _constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].sm,
        margin: '0 auto',
        height: _constants.BANNER_HEIGHT_BY_ORIENTATION[bannerOrientation].sm,
        [theme.breakpoints.up('sm')]: {
            height: _constants.BANNER_HEIGHT_BY_ORIENTATION[bannerOrientation].md,
            margin: 0,
            maxWidth: _constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md,
            order: alignment === 'left' ? 0 : 1
        }
    };
});
const StyledBannerImage = exports.StyledBannerImage = (0, _mui.styled)('img', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { alignment, theme, bannerOrientation } = param;
    return {
        height: '100%',
        maxWidth: theme.breakpoints.values.sm,
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: _constants.BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md
        }
    };
});
const Content = exports.Content = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        bottom: 0,
        boxSizing: 'border-box',
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(6, 8),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3, 1)
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6, 8)
        }
    };
});
const HeadlineTypography = exports.HeadlineTypography = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        fontFamily: 'SansOri, SansOriFallback, sans-serif',
        fontSize: '2.5rem',
        overflow: 'hidden',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            fontSize: '3rem',
            WebkitLineClamp: 3
        }
    };
});
const StyledButton = exports.StyledButton = (0, _mui.styled)(_mui.Button)((param)=>{
    let { theme } = param;
    return {
        fontSize: '1.4rem',
        lineHeight: 1.2,
        padding: theme.spacing(3, 6)
    };
});
const Link = exports.Link = (0, _mui.styled)('a', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { desktopColor, mobileColor, theme } = param;
    return {
        // in default state, focus state and hover state
        '&, &:hover, &:focus': {
            color: mobileColor,
            [theme.breakpoints.up('sm')]: {
                color: desktopColor
            }
        }
    };
});
