"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TitleSkeleton = exports.SkeletonWrapper = exports.Root = exports.ItemsWrap = exports.ItemSkeleton = exports.ButtonSkeleton = void 0;
var _mui = require("@ori-ui/mui");
var _components = require("../../components");
const Root = exports.Root = (0, _mui.styled)(_components.Root)((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        gap: theme.spacing(4)
    };
});
const ItemsWrap = exports.ItemsWrap = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'grid',
        gap: theme.spacing(4, 2),
        gridTemplateColumns: '160px 160px',
        gridTemplateRows: '160px 160px',
        justifyContent: 'center',
        order: 3,
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(10),
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridTemplateRows: 'auto',
            width: '100%',
            order: 2,
            maxWidth: 950
        }
    };
});
const SkeletonWrapper = exports.SkeletonWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            height: 0,
            overflow: 'hidden',
            paddingTop: '100%',
            position: 'relative',
            width: '100%'
        }
    };
});
const ItemSkeleton = exports.ItemSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        width: '160px',
        height: '160px',
        borderRadius: '50%',
        position: 'static',
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        }
    };
});
const ButtonSkeleton = exports.ButtonSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        height: theme.spacing(9),
        width: 160,
        borderRadius: theme.spacing(5),
        order: 2,
        [theme.breakpoints.up('sm')]: {
            order: 3
        }
    };
});
const TitleSkeleton = exports.TitleSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        height: 45,
        width: 140,
        order: 1
    };
});
