"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment BeautyVideo on Frontpage {\n    beautyVideo {\n      video {\n        id\n        playInLoop\n      }\n      link {\n        title\n        relativeUrl\n      }\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.BEAUTY_VIDEO_FRAGMENT = void 0;
var _fetching = require("@ori/fetching");
const BEAUTY_VIDEO_FRAGMENT = exports.BEAUTY_VIDEO_FRAGMENT = (0, _fetching.gql)(_templateObject());
