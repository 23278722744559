"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.shouldRenderWhatsNew = exports.getWhatsNew = void 0;
var _productBox = require("@ori/product-box");
const shouldRenderWhatsNew = (param)=>{
    let { productBoxes } = param;
    return productBoxes.length > 0;
};
exports.shouldRenderWhatsNew = shouldRenderWhatsNew;
const getWhatsNew = (data, isAnonymousUser)=>{
    var _data_application_frontpage_digitalPromotions, _data_application_frontpage, _data_application, _data_application_frontpage_digitalPromotions1, _data_application_frontpage1, _data_application1;
    var _data_application_frontpage_digitalPromotions_whatsNewBanners;
    const [banner] = (_data_application_frontpage_digitalPromotions_whatsNewBanners = (_data_application = data.application) === null || _data_application === void 0 ? void 0 : (_data_application_frontpage = _data_application.frontpage) === null || _data_application_frontpage === void 0 ? void 0 : (_data_application_frontpage_digitalPromotions = _data_application_frontpage.digitalPromotions) === null || _data_application_frontpage_digitalPromotions === void 0 ? void 0 : _data_application_frontpage_digitalPromotions.whatsNewBanners) !== null && _data_application_frontpage_digitalPromotions_whatsNewBanners !== void 0 ? _data_application_frontpage_digitalPromotions_whatsNewBanners : [];
    var _data_application_frontpage_digitalPromotions_whatsNewProducts;
    const productBoxes = (0, _productBox.mapProductBoxesFromGql)({
        data: [
            ...(_data_application_frontpage_digitalPromotions_whatsNewProducts = (_data_application1 = data.application) === null || _data_application1 === void 0 ? void 0 : (_data_application_frontpage1 = _data_application1.frontpage) === null || _data_application_frontpage1 === void 0 ? void 0 : (_data_application_frontpage_digitalPromotions1 = _data_application_frontpage1.digitalPromotions) === null || _data_application_frontpage_digitalPromotions1 === void 0 ? void 0 : _data_application_frontpage_digitalPromotions1.whatsNewProducts) !== null && _data_application_frontpage_digitalPromotions_whatsNewProducts !== void 0 ? _data_application_frontpage_digitalPromotions_whatsNewProducts : []
        ],
        isAnonymousUser
    });
    return {
        banner: banner !== null && banner !== void 0 ? banner : null,
        productBoxes,
        shouldRender: shouldRenderWhatsNew({
            productBoxes
        })
    };
};
exports.getWhatsNew = getWhatsNew;
