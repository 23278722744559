"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useInstance = void 0;
var _react = require("react");
const useInstance = (factory)=>{
    // use ref to persist value between re-renders
    const ref = (0, _react.useRef)();
    if (ref.current === undefined) {
        ref.current = factory();
    }
    return ref.current;
};
exports.useInstance = useInstance;
