"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.generateSrcSetWithDensity = void 0;
const generateSrcSetWithDensity = (url)=>{
    const urlParams = new URL(url).searchParams;
    const width = urlParams.get('w');
    if (!width) {
        return url;
    }
    const densityMap = [
        {
            density: 1,
            url
        },
        {
            density: 2,
            url: url.replace("&w=".concat(width), "&w=".concat(Number(width) * 2))
        }
    ];
    return densityMap.map((size)=>"".concat(size.url, " ").concat(size.density, "x")).join(',');
};
exports.generateSrcSetWithDensity = generateSrcSetWithDensity;
