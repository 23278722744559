"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ViewAllBrandsButton = exports.Title = exports.Root = exports.ButtonWrapper = exports.Brands = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'grid',
        flexDirection: 'column',
        padding: theme.spacing(10, 0),
        margin: theme.spacing(0, 3),
        gap: theme.spacing(4)
    };
});
const Title = exports.Title = (0, _mui.styled)(_mui.Typography)((param)=>{
    let { theme } = param;
    return {
        order: 1
    };
});
const Brands = exports.Brands = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'grid',
        gap: theme.spacing(4, 2),
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        justifySelf: 'center',
        order: 3,
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(10),
            maxWidth: 950,
            order: 2,
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridTemplateRows: 'auto'
        }
    };
});
const ButtonWrapper = exports.ButtonWrapper = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        order: 2,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            order: 3
        }
    };
});
const ViewAllBrandsButton = exports.ViewAllBrandsButton = (0, _mui.styled)(_mui.Button)((param)=>{
    let { theme } = param;
    return {
        alignSelf: 'center',
        textTransform: 'uppercase'
    };
});
