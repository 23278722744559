"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment WhatsNewBanners on WhatsNewBanner {\n    fontColor\n    mobileFontColor\n    headline\n    imageUrl\n    link {\n      relativeUrl\n      title\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.WHATS_NEW_BANNERS_FRAGMENT = void 0;
var _fetching = require("@ori/fetching");
const WHATS_NEW_BANNERS_FRAGMENT = exports.WHATS_NEW_BANNERS_FRAGMENT = (0, _fetching.gql)(_templateObject());
