"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _Header = require("./Header");
Object.keys(_Header).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _Header[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _Header[key];
        }
    });
});
var _Products = require("./Products");
Object.keys(_Products).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _Products[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _Products[key];
        }
    });
});
var _ViewMoreButton = require("./ViewMoreButton");
Object.keys(_ViewMoreButton).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _ViewMoreButton[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _ViewMoreButton[key];
        }
    });
});
var _Banner = require("./Banner");
Object.keys(_Banner).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _Banner[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _Banner[key];
        }
    });
});
var _BannerPlaceholder = require("./BannerPlaceholder");
Object.keys(_BannerPlaceholder).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _BannerPlaceholder[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _BannerPlaceholder[key];
        }
    });
});
