"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        marginBlockEnd: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            marginBlockEnd: theme.spacing(12)
        }
    };
});
