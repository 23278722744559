"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.translationsNamespace = exports.defaultTranslations = void 0;
/* istanbul ignore file */ /**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */ const defaultTranslations = exports.defaultTranslations = {
    bestOffers: 'Best offers',
    bestSellers: 'Best sellers',
    close: 'Close',
    iconicProducts: 'Icons of our time',
    play: 'Play',
    viewAll: 'View all',
    viewAllBrands: 'View all brands',
    viewOffers: 'View offer',
    whatsNew: "What's new"
};
const translationsNamespace = exports.translationsNamespace = 'frontpage';
