"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.PORTRAIT_SM_WIDTH = exports.PORTRAIT_SM_HEIGHT = exports.PORTRAIT_MD_WIDTH = exports.PORTRAIT_MD_HEIGHT = exports.LANDSCAPE_SM_WIDTH = exports.LANDSCAPE_SM_HEIGHT = exports.LANDSCAPE_MD_WIDTH = exports.LANDSCAPE_MD_HEIGHT = exports.BANNER_WIDTH_BY_ORIENTATION = exports.BANNER_HEIGHT_BY_ORIENTATION = void 0;
const LANDSCAPE_MD_WIDTH = exports.LANDSCAPE_MD_WIDTH = 545;
const PORTRAIT_MD_WIDTH = exports.PORTRAIT_MD_WIDTH = 300;
const LANDSCAPE_SM_WIDTH = exports.LANDSCAPE_SM_WIDTH = 360;
const PORTRAIT_SM_WIDTH = exports.PORTRAIT_SM_WIDTH = 300;
const BANNER_WIDTH_BY_ORIENTATION = exports.BANNER_WIDTH_BY_ORIENTATION = {
    landscape: {
        md: LANDSCAPE_MD_WIDTH,
        sm: LANDSCAPE_SM_WIDTH
    },
    portrait: {
        md: PORTRAIT_MD_WIDTH,
        sm: PORTRAIT_SM_WIDTH
    }
};
const LANDSCAPE_MD_HEIGHT = exports.LANDSCAPE_MD_HEIGHT = 380;
const PORTRAIT_MD_HEIGHT = exports.PORTRAIT_MD_HEIGHT = 455;
const LANDSCAPE_SM_HEIGHT = exports.LANDSCAPE_SM_HEIGHT = 215;
const PORTRAIT_SM_HEIGHT = exports.PORTRAIT_SM_HEIGHT = 455;
const BANNER_HEIGHT_BY_ORIENTATION = exports.BANNER_HEIGHT_BY_ORIENTATION = {
    landscape: {
        md: LANDSCAPE_MD_HEIGHT,
        sm: LANDSCAPE_SM_HEIGHT
    },
    portrait: {
        md: PORTRAIT_MD_HEIGHT,
        sm: PORTRAIT_SM_HEIGHT
    }
};
