"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = exports.LinkArea = exports.CloseIconButton = void 0;
var _components = require("@ori-ui/components");
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 101,
        background: theme.palette.default.main,
        color: theme.palette.common.white
    };
});
const LinkArea = exports.LinkArea = (0, _mui.styled)(_mui.Link)((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        color: theme.palette.common.white,
        '&:hover, &:focus, &:active': {
            color: theme.palette.common.white
        }
    };
});
const CloseIconButton = exports.CloseIconButton = (0, _mui.styled)(_components.IconButton)((param)=>{
    let { theme } = param;
    return {
        color: theme.palette.common.white,
        position: 'absolute',
        top: theme.spacing(3),
        right: theme.spacing(3),
        '&:hover, &:focus, &:active': {
            borderColor: theme.palette.common.white
        }
    };
});
