"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TitleSkeleton = exports.TextWrap = void 0;
var _mui = require("@ori-ui/mui");
var _components = require("../../components");
const TitleSkeleton = exports.TitleSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)({
    maxWidth: '490px',
    width: '100%'
});
const TextWrap = exports.TextWrap = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: theme.spacing(1),
        padding: theme.spacing(0, 3),
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px',
            padding: 0
        }
    };
});
