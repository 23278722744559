"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.shouldRenderMotivationContent = exports.getMotivationContent = void 0;
const shouldRenderMotivationContent = (param)=>{
    let { headline, text } = param;
    return !!headline && !!text;
};
exports.shouldRenderMotivationContent = shouldRenderMotivationContent;
const getMotivationContent = (data)=>{
    var _data_headline, _data_text;
    const parsedData = {
        headline: (_data_headline = data === null || data === void 0 ? void 0 : data.headline) !== null && _data_headline !== void 0 ? _data_headline : '',
        text: (_data_text = data === null || data === void 0 ? void 0 : data.text) !== null && _data_text !== void 0 ? _data_text : ''
    };
    return {
        ...parsedData,
        shouldRender: shouldRenderMotivationContent(parsedData)
    };
};
exports.getMotivationContent = getMotivationContent;
