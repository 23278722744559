"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _bestOffersPlaceholder = require("./bestOffersPlaceholder");
Object.keys(_bestOffersPlaceholder).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _bestOffersPlaceholder[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _bestOffersPlaceholder[key];
        }
    });
});
var _categoryListingPlaceholder = require("./categoryListingPlaceholder");
Object.keys(_categoryListingPlaceholder).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _categoryListingPlaceholder[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _categoryListingPlaceholder[key];
        }
    });
});
var _heroBannerDesktopPlaceholder = require("./heroBannerDesktopPlaceholder");
Object.keys(_heroBannerDesktopPlaceholder).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _heroBannerDesktopPlaceholder[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _heroBannerDesktopPlaceholder[key];
        }
    });
});
var _heroBannerMobilePlaceholder = require("./heroBannerMobilePlaceholder");
Object.keys(_heroBannerMobilePlaceholder).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _heroBannerMobilePlaceholder[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _heroBannerMobilePlaceholder[key];
        }
    });
});
var _whatsNewPlaceholder = require("./whatsNewPlaceholder");
Object.keys(_whatsNewPlaceholder).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _whatsNewPlaceholder[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _whatsNewPlaceholder[key];
        }
    });
});
