"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = exports.Content = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('li')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        flex: 'auto',
        gap: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            flex: 1,
            maxWidth: 300,
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(2)
        }
    };
});
const Content = exports.Content = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        gap: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
            gap: theme.spacing(2)
        }
    };
});
