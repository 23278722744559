"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment VipCustomerBanner on Query {\n    vipCustomerBanner {\n      linkTitle\n      linkUrl\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
function _templateObject1() {
    const data = _tagged_template_literal._([
        "\n  query GetVipCustomerBanner {\n    ...VipCustomerBanner\n  }\n  ",
        "\n"
    ]);
    _templateObject1 = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.vipCustomerBannerQuery = exports.VIP_CUSTOMER_BANNER_FRAGMENT = void 0;
var _fetching = require("@ori/fetching");
const VIP_CUSTOMER_BANNER_FRAGMENT = exports.VIP_CUSTOMER_BANNER_FRAGMENT = (0, _fetching.gql)(_templateObject());
const vipCustomerBannerQuery = exports.vipCustomerBannerQuery = (0, _fetching.gql)(_templateObject1(), VIP_CUSTOMER_BANNER_FRAGMENT);
