"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.shouldRenderBeautyVideo = exports.getBeautyVideo = void 0;
const shouldRenderBeautyVideo = (param)=>{
    let { relativeUrl, title, videoId } = param;
    return !!videoId && !!relativeUrl && !!title;
};
exports.shouldRenderBeautyVideo = shouldRenderBeautyVideo;
const getBeautyVideo = (data)=>{
    var _data_link, _data_link1, _data_video, _data_video1;
    var _data_link_relativeUrl, _data_link_title, _data_video_id, _data_video_playInLoop;
    const parsedData = {
        relativeUrl: (_data_link_relativeUrl = data === null || data === void 0 ? void 0 : (_data_link = data.link) === null || _data_link === void 0 ? void 0 : _data_link.relativeUrl) !== null && _data_link_relativeUrl !== void 0 ? _data_link_relativeUrl : '',
        title: (_data_link_title = data === null || data === void 0 ? void 0 : (_data_link1 = data.link) === null || _data_link1 === void 0 ? void 0 : _data_link1.title) !== null && _data_link_title !== void 0 ? _data_link_title : '',
        videoId: (_data_video_id = data === null || data === void 0 ? void 0 : (_data_video = data.video) === null || _data_video === void 0 ? void 0 : _data_video.id) !== null && _data_video_id !== void 0 ? _data_video_id : '',
        videoPlayInLoop: (_data_video_playInLoop = data === null || data === void 0 ? void 0 : (_data_video1 = data.video) === null || _data_video1 === void 0 ? void 0 : _data_video1.playInLoop) !== null && _data_video_playInLoop !== void 0 ? _data_video_playInLoop : false
    };
    return {
        ...parsedData,
        shouldRender: shouldRenderBeautyVideo(parsedData)
    };
};
exports.getBeautyVideo = getBeautyVideo;
