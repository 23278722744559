"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _Products = require("./Products");
Object.keys(_Products).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _Products[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _Products[key];
        }
    });
});
var _types = require("./types");
Object.keys(_types).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _types[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _types[key];
        }
    });
});
