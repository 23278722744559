"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.onLinkClick = void 0;
var LinkClick = _interopRequireWildcard(require("@ori-events/link-click"));
var _analyticsData = require("@ori/analytics-data");
function _getRequireWildcardCache(e) {
    if ("function" != typeof WeakMap) return null;
    var r = new WeakMap(), t = new WeakMap();
    return (_getRequireWildcardCache = function(e) {
        return e ? t : r;
    })(e);
}
function _interopRequireWildcard(e, r) {
    if (!r && e && e.__esModule) return e;
    if (null === e || "object" != typeof e && "function" != typeof e) return {
        default: e
    };
    var t = _getRequireWildcardCache(r);
    if (t && t.has(e)) return t.get(e);
    var n = {
        __proto__: null
    }, a = Object.defineProperty && Object.getOwnPropertyDescriptor;
    for(var u in e)if ("default" !== u && ({}).hasOwnProperty.call(e, u)) {
        var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
        i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
    }
    return n.default = e, t && t.set(e, n), n;
}
/* istanbul ignore file */ const onLinkClick = (name, url)=>{
    window.dispatchEvent(new CustomEvent(LinkClick.Name, {
        detail: {
            globalName: '',
            localName: name,
            placement: _analyticsData.Placement.TopBrands,
            target: url
        }
    }));
};
exports.onLinkClick = onLinkClick;
