"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.cookieExists = void 0;
var _cookies = require("./cookies");
const cookieExists = (name)=>{
    const cookie = (0, _cookies.getCookie)(name);
    return cookie !== '' && cookie !== 'null' && cookie !== null && cookie !== undefined;
};
exports.cookieExists = cookieExists;
