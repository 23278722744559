"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment IconicProducts on Frontpage {\n    iconicProducts {\n      headline\n      description\n      banner {\n        ...ProductPromotionBanner\n      }\n      products {\n        ...ProductWithShades\n      }\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ICONIC_PRODUCTS_FRAGMENT = void 0;
var _fetching = require("@ori/fetching");
const ICONIC_PRODUCTS_FRAGMENT = exports.ICONIC_PRODUCTS_FRAGMENT = (0, _fetching.gql)(_templateObject());
