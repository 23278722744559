"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useNotificationBannerController = void 0;
var _useBannerVisibilityToggle = require("./useBannerVisibilityToggle");
var _useGetNotificationBanner = require("./useGetNotificationBanner");
const useNotificationBannerController = ()=>{
    const { notificationBanner } = (0, _useGetNotificationBanner.useGetNotificationBanner)();
    const { showBanner, toggleBannerVisibility } = (0, _useBannerVisibilityToggle.useBannerVisibilityToggle)();
    if (!notificationBanner || !showBanner) {
        return {
            visible: false
        };
    }
    var _notificationBanner_messageLinkTitle, _notificationBanner_messageLinkUrl, _notificationBanner_messageText;
    return {
        visible: true,
        messageLinkTitle: (_notificationBanner_messageLinkTitle = notificationBanner.messageLinkTitle) !== null && _notificationBanner_messageLinkTitle !== void 0 ? _notificationBanner_messageLinkTitle : '',
        messageLinkUrl: (_notificationBanner_messageLinkUrl = notificationBanner.messageLinkUrl) !== null && _notificationBanner_messageLinkUrl !== void 0 ? _notificationBanner_messageLinkUrl : '',
        messageText: (_notificationBanner_messageText = notificationBanner.messageText) !== null && _notificationBanner_messageText !== void 0 ? _notificationBanner_messageText : '',
        onCloseClick: toggleBannerVisibility
    };
};
exports.useNotificationBannerController = useNotificationBannerController;
