"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
var _presentationUtils = require("@ori/presentation-utils");
const Root = exports.Root = (0, _mui.styled)(_mui.Button, {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)([
        'color',
        'variant'
    ])
})((param)=>{
    let { theme, alignment } = param;
    return {
        gridArea: 'title',
        alignSelf: 'center',
        justifySelf: 'center',
        margin: theme.spacing(0, 'auto'),
        order: 4,
        [theme.breakpoints.up('sm')]: {
            alignSelf: 'end',
            justifySelf: alignment === 'left' ? 'start' : 'end',
            margin: 0,
            order: 'unset'
        }
    };
});
