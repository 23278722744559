"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)(_mui.Button)((param)=>{
    let { theme } = param;
    return {
        alignSelf: 'center',
        fontSize: '1.4rem',
        height: '45px',
        marginTop: theme.spacing(6),
        width: '266px'
    };
});
