"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TextWrapper = exports.Text = exports.StyledClearIcon = exports.Root = void 0;
var _icons = require("@ori-ui/icons");
var _mui = require("@ori-ui/mui");
// istanbul ignore file -- Will be replaced with TopAreaBanner
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        background: theme.palette.purple[100],
        height: 50,
        justifyContent: 'flex-end',
        opacity: 1,
        position: 'relative',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            opacity: 0.8
        }
    };
});
const TextWrapper = exports.TextWrapper = (0, _mui.styled)('a')((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        fontWeight: theme.typography.fontWeightBold,
        height: '100%',
        width: '100%'
    };
});
const Text = exports.Text = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        lineHeight: '2.2rem',
        margin: '0 auto',
        textDecoration: 'none',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.4rem'
        }
    };
});
const StyledClearIcon = exports.StyledClearIcon = (0, _mui.styled)(_icons.Clear)((param)=>{
    let { theme } = param;
    return {
        color: theme.palette.common.white,
        cursor: 'pointer',
        fontSize: '2.2rem',
        height: 50,
        margin: theme.spacing('auto', 3),
        position: 'absolute',
        right: 0,
        top: 0,
        transition: 'none',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.7rem'
        }
    };
});
