"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment MotivationContent on Frontpage {\n    motivationContent {\n      headline\n      text\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.MOTIVATION_CONTENT_FRAGMENT = void 0;
var _fetching = require("@ori/fetching");
const MOTIVATION_CONTENT_FRAGMENT = exports.MOTIVATION_CONTENT_FRAGMENT = (0, _fetching.gql)(_templateObject());
