"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment HeroBanner on HeroBanner {\n    fontColor\n    mobileFontColor\n    headline\n    imageUrl\n    link {\n      relativeUrl\n      title\n    }\n    priority\n    smallImageUrl\n    tagline\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.HERO_BANNER_FRAGMENT = void 0;
var _fetching = require("@ori/fetching");
const HERO_BANNER_FRAGMENT = exports.HERO_BANNER_FRAGMENT = (0, _fetching.gql)(_templateObject());
