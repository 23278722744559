"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TEAM_NAME = exports.PACKAGE_NAME = exports.EMOTION_CACHE_KEY = exports.APP_ROOT_ELEMENT_ID = exports.APP_NAME = void 0;
/* istanbul ignore file */ //
// DO NOT EDIT!
// This file was autogenerated by Eddie.
// Changes may cause incorrect behavior and will be lost when the file is regenerated.
//
// Run `npx eddie update workspace` to regenerate.
//
const APP_NAME = exports.APP_NAME = 'frontpage';
const PACKAGE_NAME = exports.PACKAGE_NAME = '@ori/frontpage';
const TEAM_NAME = exports.TEAM_NAME = 'Presentation';
const EMOTION_CACHE_KEY = exports.EMOTION_CACHE_KEY = "".concat(APP_NAME, "-emotion-cache-key");
const APP_ROOT_ELEMENT_ID = exports.APP_ROOT_ELEMENT_ID = "".concat(APP_NAME, "-root");
