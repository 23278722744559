"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TextWrap = exports.ButtonSkeleton = exports.BenefitIconSkeleton = void 0;
var _mui = require("@ori-ui/mui");
var _components = require("../../components");
var _constants = require("../../components/VipBenefitIcon/constants");
const TextWrap = exports.TextWrap = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        width: '100%',
        display: 'grid',
        gap: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            justifyItems: 'center'
        }
    };
});
const ButtonSkeleton = exports.ButtonSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        marginTop: theme.spacing(3),
        alignSelf: 'center',
        height: '45px',
        borderRadius: theme.spacing(5)
    };
});
const BenefitIconSkeleton = exports.BenefitIconSkeleton = (0, _mui.styled)(_components.UnifiedSkeleton)((param)=>{
    let { theme } = param;
    return {
        height: _constants.VIP_BENEFIT_ICON_MOBILE_SIZE,
        margin: 6,
        width: _constants.VIP_BENEFIT_ICON_MOBILE_SIZE,
        [theme.breakpoints.up('sm')]: {
            height: _constants.VIP_BENEFIT_ICON_DESKTOP_SIZE,
            margin: 7,
            width: _constants.VIP_BENEFIT_ICON_DESKTOP_SIZE
        }
    };
});
