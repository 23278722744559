"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _defaultTranslations = require("./defaultTranslations");
Object.keys(_defaultTranslations).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _defaultTranslations[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _defaultTranslations[key];
        }
    });
});
var _feature = require("./feature");
Object.keys(_feature).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _feature[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _feature[key];
        }
    });
});
var _layout = require("./layout");
Object.keys(_layout).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _layout[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _layout[key];
        }
    });
});
var _testIds = require("./testIds");
Object.keys(_testIds).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _testIds[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _testIds[key];
        }
    });
});
