"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ServerSessionStorage = void 0;
var _logger = require("../../logger");
/* istanbul ignore file */ const localLogger = _logger.mainLogger.createChild('ServerSessionStorage');
class ServerSessionStorage {
    getItem(key) {
        return null;
    }
    removeItem(key) {
        localLogger.warn("Tried remove sessionStorage key “".concat(key, "” even though environment is not a client"));
    }
    setItem(key, value) {
        localLogger.warn("Tried setting sessionStorage key “".concat(key, "” even though environment is not a client"));
    }
}
exports.ServerSessionStorage = ServerSessionStorage;
