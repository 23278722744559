"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useVipCustomerBannerData = void 0;
var _auth = require("@ori/auth");
var _configurationManager = require("@ori/configuration-manager");
var _fetching = require("@ori/fetching");
var _presentationHttp = require("@ori/presentation-http");
var _vipCustomerBanner = require("@ori/vip-customer-banner");
var _contexts = require("../contexts");
// istanbul ignore file -- Will be replaced with TopAreaBanner
const CACHE_CONTROL = 'Cache-Control';
const useVipCustomerBannerData = (param)=>{
    let { enabled } = param;
    const { isAnonymousUser } = (0, _auth.useToken)();
    const { graphQlNetUrl } = (0, _configurationManager.useServiceUrls)([
        'graphQlNet'
    ]);
    const { headers, loading } = (0, _presentationHttp.useHeaders)();
    const skip = loading || !enabled || isAnonymousUser;
    const { data, error } = (0, _fetching.useGraphqlQuery)({
        query: _vipCustomerBanner.vipCustomerBannerQuery,
        skip,
        url: graphQlNetUrl,
        headers: {
            ...headers,
            [CACHE_CONTROL]: 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }
    });
    const { vipCustomerBanner } = (0, _contexts.useFrontpage)();
    return {
        loading: !skip && !data && !error,
        vipCustomerBannerData: isAnonymousUser ? vipCustomerBanner : data === null || data === void 0 ? void 0 : data.vipCustomerBanner
    };
};
exports.useVipCustomerBannerData = useVipCustomerBannerData;
