"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _AlignmentTypes = require("./AlignmentTypes");
Object.keys(_AlignmentTypes).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _AlignmentTypes[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _AlignmentTypes[key];
        }
    });
});
var _DeviceColorsProps = require("./DeviceColorsProps");
Object.keys(_DeviceColorsProps).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _DeviceColorsProps[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _DeviceColorsProps[key];
        }
    });
});
