"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.shouldRenderIconicProducts = exports.getIconicProducts = void 0;
var _productBox = require("@ori/product-box");
const shouldRenderIconicProducts = (param)=>{
    let { productBoxes } = param;
    return productBoxes.length > 0;
};
exports.shouldRenderIconicProducts = shouldRenderIconicProducts;
const getIconicProducts = (data, isAnonymousUser)=>{
    const banner = data === null || data === void 0 ? void 0 : data.banner;
    var _data_products;
    const productBoxes = (0, _productBox.mapProductBoxesFromGql)({
        data: [
            ...(_data_products = data === null || data === void 0 ? void 0 : data.products) !== null && _data_products !== void 0 ? _data_products : []
        ],
        isAnonymousUser
    });
    var _data_description, _data_headline;
    return {
        banner: banner !== null && banner !== void 0 ? banner : null,
        description: (_data_description = data === null || data === void 0 ? void 0 : data.description) !== null && _data_description !== void 0 ? _data_description : null,
        headline: (_data_headline = data === null || data === void 0 ? void 0 : data.headline) !== null && _data_headline !== void 0 ? _data_headline : null,
        productBoxes,
        shouldRender: shouldRenderIconicProducts({
            productBoxes
        })
    };
};
exports.getIconicProducts = getIconicProducts;
