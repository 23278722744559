"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useSessionStorage = useSessionStorage;
var _mui = require("@ori-ui/mui");
var _react = require("react");
var _factory = require("../utils/sessionStorage/factory");
var _useInstance = require("./useInstance");
function useSessionStorage(key, initialValue) {
    let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const storage = (0, _useInstance.useInstance)(_factory.createSessionStoreRepository);
    const { initializeWithValue = true } = options;
    // Get from session storage then
    // parse stored json or return initialValue
    const readValue = (0, _react.useCallback)(()=>{
        try {
            return storage.getOrCreate(key, initialValue);
        } catch (error) {
            return initialValue;
        }
    }, [
        initialValue,
        key,
        storage
    ]);
    const [storedValue, setStoredValue] = (0, _react.useState)(()=>{
        if (initializeWithValue) {
            return readValue();
        }
        return initialValue;
    });
    const setValue = (0, _mui.useEventCallback)((value)=>{
        try {
            setStoredValue((originalValue)=>{
                const newValue = value instanceof Function ? value(originalValue) : value;
                storage.save(key, newValue);
                return newValue;
            });
        } catch (error) {
        /* empty */ }
    });
    (0, _react.useEffect)(()=>{
        setStoredValue(readValue());
    }, [
        key,
        readValue
    ]);
    return {
        value: storedValue,
        setValue
    };
}
