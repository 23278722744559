"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.VIEW_MORE_BUTTON_RIGHT = exports.VIEW_MORE_BUTTON_LEFT = exports.PRODUCTS_ROOT = exports.PRODUCTS_BOX = exports.HEADER_TITLE = exports.HEADER_ROOT = exports.HEADER_DESCRIPTION = exports.BANNER_ROOT_RIGHT = exports.BANNER_ROOT_PLACEHOLDER_RIGHT = exports.BANNER_ROOT_PLACEHOLDER_LEFT = exports.BANNER_ROOT_LEFT = exports.BANNER_LINK = exports.BANNER_IMAGE = exports.BANNER_HEADLINE = exports.BANNER_BUTTON_TITLE = void 0;
// View more button
const VIEW_MORE_BUTTON_LEFT = exports.VIEW_MORE_BUTTON_LEFT = 'button-left';
const VIEW_MORE_BUTTON_RIGHT = exports.VIEW_MORE_BUTTON_RIGHT = 'button-right';
// Header
const HEADER_ROOT = exports.HEADER_ROOT = 'header';
const HEADER_TITLE = exports.HEADER_TITLE = 'title';
const HEADER_DESCRIPTION = exports.HEADER_DESCRIPTION = 'description';
// Banner
const BANNER_ROOT_LEFT = exports.BANNER_ROOT_LEFT = 'banner-left';
const BANNER_ROOT_RIGHT = exports.BANNER_ROOT_RIGHT = 'banner-right';
const BANNER_LINK = exports.BANNER_LINK = 'link';
const BANNER_IMAGE = exports.BANNER_IMAGE = 'image';
const BANNER_BUTTON_TITLE = exports.BANNER_BUTTON_TITLE = 'button-title';
const BANNER_HEADLINE = exports.BANNER_HEADLINE = 'headline';
// BannerPlaceholder
const BANNER_ROOT_PLACEHOLDER_LEFT = exports.BANNER_ROOT_PLACEHOLDER_LEFT = 'banner-placeholder-left';
const BANNER_ROOT_PLACEHOLDER_RIGHT = exports.BANNER_ROOT_PLACEHOLDER_RIGHT = 'banner-placeholder-right';
// Products
const PRODUCTS_ROOT = exports.PRODUCTS_ROOT = 'products';
const PRODUCTS_BOX = exports.PRODUCTS_BOX = 'product-box';
