"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getCategoryNavigationItems = void 0;
const getCategoryNavigationItems = (param)=>{
    let { categoryNavigation } = param;
    return (categoryNavigation !== null && categoryNavigation !== void 0 ? categoryNavigation : []).filter((categoryNavigationItem)=>Boolean((categoryNavigationItem === null || categoryNavigationItem === void 0 ? void 0 : categoryNavigationItem.relativeUrl) && categoryNavigationItem.title)).map((param)=>{
        let { relativeUrl, title } = param;
        return {
            id: relativeUrl,
            relativeUrl,
            title
        };
    });
};
exports.getCategoryNavigationItems = getCategoryNavigationItems;
