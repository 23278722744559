"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CAROUSEL_IMAGE_SKELETON = exports.CAROUSEL_IMAGE_ROOT = exports.CAROUSEL_IMAGE = exports.CAROUSEL_BANNER_TAGLINE = exports.CAROUSEL_BANNER_ROOT = exports.CAROUSEL_BANNER_MOBILE_PLACEHOLDER = exports.CAROUSEL_BANNER_HEADLINE = exports.CAROUSEL_BANNER_DESKTOP_PLACEHOLDER = exports.CAROUSEL_BANNER_BUTTON = exports.CAROUSEL = void 0;
const CAROUSEL = exports.CAROUSEL = 'carousel';
const CAROUSEL_IMAGE_SKELETON = exports.CAROUSEL_IMAGE_SKELETON = 'carousel-image-skeleton';
const CAROUSEL_IMAGE_ROOT = exports.CAROUSEL_IMAGE_ROOT = 'carousel-image-root';
const CAROUSEL_IMAGE = exports.CAROUSEL_IMAGE = 'carousel-image';
const CAROUSEL_BANNER_TAGLINE = exports.CAROUSEL_BANNER_TAGLINE = 'carousel-banner-tagline';
const CAROUSEL_BANNER_HEADLINE = exports.CAROUSEL_BANNER_HEADLINE = 'carousel-banner-headline';
const CAROUSEL_BANNER_BUTTON = exports.CAROUSEL_BANNER_BUTTON = 'carousel-banner-button';
const CAROUSEL_BANNER_ROOT = exports.CAROUSEL_BANNER_ROOT = 'carousel-banner-root';
const CAROUSEL_BANNER_DESKTOP_PLACEHOLDER = exports.CAROUSEL_BANNER_DESKTOP_PLACEHOLDER = 'carousel-banner-desktop-placeholder';
const CAROUSEL_BANNER_MOBILE_PLACEHOLDER = exports.CAROUSEL_BANNER_MOBILE_PLACEHOLDER = 'carousel-banner-mobile-placeholder';
